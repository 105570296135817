import React from 'react';
import './Confetti.css';

function Confetti(props) {
  const customStyle = {};
  return (
    <span className='Confetti' style={customStyle}>
    </span>
  );
}

export default Confetti;
