export const generateUrl = (props) => {
    const urlPort = window.location.port ? `:${window.location.port}` : '';
    
    // Check if the current URL path contains '/remote'
    const pathSegments = window.location.pathname.split('/');
    const isRemote = pathSegments.includes('remote');
    
    // Set pathPrefix based on whether 'remote' is part of the current path
    const pathPrefix = isRemote ? 'remote/' : '';

    // Construct the query string for auto start if needed
    const queryString = props.autoStart ? '?start=auto' : '';

    // Return the full URL with the appropriate path and query string
    return `${window.location.protocol}//${window.location.hostname}${urlPort}/${pathPrefix}${props.level}${queryString}`;
}
