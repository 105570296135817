import React from 'react';
import './Carrot.css';

function Carrot(props) {
  const customStyle = {};
  return (
    <span className='Carrot' style={customStyle}>
    </span>
  );
}

export default Carrot;
