import React from 'react';
import './Heart.css';

function Heart(props) {
  const customStyle = {};
  return (
    <span className='Heart' style={customStyle}>
    </span>
  );
}

export default Heart;
